<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn
        v-if="bookId"
        :to="{ name: 'CabinetBookEdit', params: { id: bookId } }"
        class="no-active"
        icon
      >
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="chapterId">{{
        $vuetify.breakpoint.smAndUp ? "Редактировать главу" : "Редактировать"
      }}</v-toolbar-title>
      <v-toolbar-title v-else>{{
        $vuetify.breakpoint.smAndUp ? "Добавить главу" : "Добавить"
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="chapterId && chapter"
        :to="{
          name: 'ReaderShowFull',
          params: { bookSlug: book.slug, id: chapter.id },
        }"
        text
        tile
        >{{
          $vuetify.breakpoint.smAndUp ? "Просмотр главы" : "Просмотр"
        }}</v-btn
      >
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="10">
          <!-- <v-card class="elevation-0"> -->
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  name="inputTitle"
                  label="Название части"
                  required
                  :rules="titleRules"
                  class="mb-4"
                ></v-text-field>

                <!-- <v-textarea
                      name="inputBody"
                      label="Текст"
                      value
                      v-model="body"
                      :rules="bodyRules"
                  ></v-textarea>-->

                <quill-editor
                  ref="myQuillEditor"
                  v-model="body"
                  :options="editorOption"
                />
                <div id="counterQuill"></div>
                <v-file-input
                  v-model="fileDocx"
                  clearable
                  :loading="loadingDocx"
                  :disabled="disabledDocx"
                  accept=".docx"
                  :rules="docxRules"
                  @change="uploadeDocx"
                  :success-messages="successDocx"
                  label="Добавить текст из файла"
                ></v-file-input>
                <!-- <v-checkbox
                    v-model="status"
                    label="Опубликовать"
                  ></v-checkbox> -->
                <v-radio-group
                  class="publish-filters-group"
                  v-model="publishType"
                >
                  <v-subheader class="px-0">Публикация</v-subheader>
                  <v-radio
                    label="Опубликовать сейчас"
                    value="publish"
                  ></v-radio>
                  <v-radio
                    label="Сохранить как черновик (без публикации)"
                    value="draft"
                  ></v-radio>
                  <v-radio
                    label="Назначить дату публикации"
                    value="datePublish"
                  ></v-radio>
                </v-radio-group>
                <div v-if="publishType == 'datePublish'">
                  <v-menu
                    ref="menuDate"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :min-width="$vuetify.breakpoint.xsOnly ? '100%' : '290px'"
                  >
                    <!-- :return-value.sync="date" -->
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateToPublish"
                        label="Дата отложенной публикации"
                        prepend-icon="$vuetify.icons.calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      v-model="dateToPublish"
                      no-title
                      :min="minDate.toISOString().substr(0, 10)"
                      :full-width="$vuetify.breakpoint.xsOnly"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuDate = false"
                        >Закрыть</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuDate.save(dateToPublish)"
                        >Принять</v-btn
                      >
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menu"
                    v-model="menuClock"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="timeToPublish"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeToPublish"
                        label="Время отложенной публикации"
                        prepend-icon="$vuetify.icons.clock_outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuClock"
                      v-model="timeToPublish"
                      full-width
                      format="24hr"
                      landscape
                      scrollable
                      no-title
                      @click:minute="$refs.menu.save(timeToPublish)"
                    ></v-time-picker>
                  </v-menu>
                </div>
                <v-checkbox
                  v-if="!oldBookStatus && !bookPaid"
                  v-model="bookStatus"
                  label="Опубликовать книгу"
                ></v-checkbox>
                <v-checkbox
                  v-if="!oldBookFinish && !bookPaid"
                  v-model="bookFinish"
                  :false-value="0"
                  :true-value="1"
                  label="Книга завершена"
                ></v-checkbox>

                <v-btn
                  v-if="chapterId"
                  dark
                  color="primary"
                  @click="updateChapter"
                  >Обновить</v-btn
                >
                <v-btn v-else dark color="primary" @click="submit"
                  >Сохранить</v-btn
                >

                <!-- <input
                    ref="inputDocx"
                    type="file"
                    @change="uploadeDocx"
                  />-->
              </v-col>
            </v-row>
          </v-form>
          <!-- </v-card> -->
        </v-col>
      </v-row>

      <!-- <div class="custom-file">
        <input
          ref="image"
          @change="imageUpload($event)"
          type="file"
          class="custom-file-input"
          id="imageUpload"
          aria-describedby="imageUploadAddon"
        />
        <label class="custom-file-label" for="imageUpload">Choose file</label>
      </div>-->
    </v-container>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import Quill from "quill";

// import quillCounter from "@/plugins/quill/quillCounter.js";

// import ImageResize from "quill-image-resize";
// Quill.register("modules/quillCounter", quillCounter);

// Quill.register("modules/imageResize", ImageResize);

import { quillOptions } from "@/plugins/quill/quillOptions";
// import { toolbarOptions } from "@/plugins/quill/toolbarOptions";
import { mapGetters } from "vuex";
export default {
  components: {
    quillEditor,
  },
  data: () => ({
    menuDate: false,
    menuClock: false,
    dateToPublish: "",
    timeToPublish: "1:00",
    publishType: "publish", // draft , datePublish
    editorOption: quillOptions,
    // editorOption: {
    //   formats: [
    //     "undo",
    //     "redo",
    //     "bold",
    //     "italic",
    //     "underline",
    //     "strike",
    //     "color",
    //     "background",
    //     "list",
    //     "indent",
    //     "blockquote",
    //     "align",
    //     "image",
    //     "width",
    //     "clean",
    //   ],
    //   modules: {
    //     quillCounter: {
    //       container: "#counterQuill",
    //       unit: "(знаков)",
    //       max: 50000,
    //       min: 0,
    //     },
    //     imageResize: {
    //       displayStyles: {
    //         backgroundColor: "black",
    //         border: "none",
    //         color: "white",
    //       },
    //       modules: ["Resize", "DisplaySize", "Toolbar"],
    //     },
    //     history: {
    //       delay: 2000,
    //       maxStack: 500,
    //       userOnly: true,
    //     },
    //     toolbar: {
    //       container: toolbarOptions,
    //        handlers: {
    //     redo() {
    //       this.quill.history.redo();
    //     },
    //     undo() {
    //       this.quill.history.undo();
    //     }}
    //     },
    //   },
    // },
    chapterId: null, //Id серии которую будем редактировать
    bookId: null, //Id серии которую будем редактировать
    valid: true,
    title: "",
    body: "",
    status: false,
    bookStatus: false,
    oldBookStatus: false,
    bookFinish: false,
    oldBookFinish: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    titleRules: [
      (v) => !!v || "Поле обязательно",
      (v) => v.length >= 1 || "Минимум 1 символ",
    ],
    bodyRules: [
      // v => !!v || 'Поле обязательно',
      (v) => !!v === v.length >= 10 || "Минимум 10 символов",
    ],
    fileDocx: null,
    loadingDocx: false,
    disabledDocx: false,
    successDocx: "",
    docxRules: [
      (v) => !v || v.size < 2000000 || "Рзмер файла не должен превышать 2 MB!",
    ],
    images: [],
    bookPaid: 0,
  }),
  methods: {
    validate() {
      let text_count = this.$refs.myQuillEditor.quill
        .getModule("quillCounter")
        .getCount();
      return (
        this.$refs.form.validate() &&
        text_count <= this.editorOption.modules.quillCounter.max &&
        text_count >= this.editorOption.modules.quillCounter.min
      );
    },
    submit() {
      if (!this.validate()) return;
      let text_count = this.$refs.myQuillEditor.quill
        .getModule("quillCounter")
        .getCount();
      this.loading = true;
      let app = this;
      this.$store
        .dispatch("cabinet_chapters/addChapter", {
          title: app.title,
          body: app.body,
          // status: app.status,
          bookStatus: app.bookStatus,
          bookFinish: app.bookFinish,
          book_id: app.bookId,
          text_count: text_count,
          publishType: app.publishType,
          dateToPublish: app.dateToPublish,
        })
        .then((response) => {
          // app.$router.push({
          //   name: "CabinetChapterEdit",
          //   params: { id: response.data.chapter.id }
          // });
          app.$router.push({
            name: "CabinetBookEdit",
            params: { id: response.data.chapter.book_id },
          });
        });
    },
    updateChapter() {
      if (!this.validate()) return;
      let text_count = this.$refs.myQuillEditor.quill
        .getModule("quillCounter")
        .getCount();
      this.loading = true;
      let app = this;
      this.$store
        .dispatch("cabinet_chapters/updateChapter", {
          chapter_id: app.chapterId,
          title: app.title,
          body: app.body,
          // status: app.status,
          bookStatus: app.bookStatus,
          bookFinish: app.bookFinish,
          book_id: app.bookId,
          text_count: text_count,
          publishType: app.publishType,
          dateToPublish: app.dateToPublish,
          timeToPublish: app.timeToPublish,
        })
        .then((response) => {
          // app.$router.push({
          //   name: "CabinetChapterEdit",
          //   params: { id: response.data.chapter.id }
          // });
          app.$router.push({
            name: "CabinetBookEdit",
            params: { id: response.data.chapter.book_id },
          });
        });
    },
    getChapter() {
      let app = this;
      this.$store
        .dispatch("cabinet_chapters/getEditChapter", {
          chapter_id: app.chapterId,
        })
        .then((response) => {
          app.bookId = response.data.chapter.book_id;
          app.bookPaid = +app.chapter.book.paid;
        });
    },
    getChapterCreate() {
      let app = this;
      this.$store
        .dispatch("cabinet_chapters/getCreateChapter", {
          book_id: app.bookId,
        })
        .then(() => {
          app.bookStatus = +app.book.status;
          app.oldBookStatus = app.bookStatus;
          app.bookFinish = +app.book.finish;
          app.oldBookFinish = app.bookFinish;
          app.bookPaid = +app.book.paid;
        });
    },
    uploadeDocx() {
      // let input = event.target;
      // if (!input.files || !input.files[0]) {
      //   return;
      // }
      // this.fileDocx = input.files[0];
      this.loadingDocx = true;
      this.disabledDocx = true;
      this.successDocx = "";
      // let app = this;
      let formData = new FormData();
      formData.append("file", this.fileDocx);
      formData.append("_method", "PUT"); //костыль для laravel
      let app = this;

      this.$store
        .dispatch("cabinet_chapters/uploadChapterFile", formData)
        .then((responce) => {
          app.body = app.body + responce.data.convert;
          app.successDocx =
            "Данные успешно добавлены, не забудьте сохранить изменения!";
          app.clearDocx();
        })
        .catch(() => {
          app.clearDocx();
        });
    },
    clearDocx() {
      this.fileDocx = null;
      this.loadingDocx = false;
      this.disabledDocx = false;
    },
    updateData() {
      // console.log('update_data')
      this.title = this.chapter.title;
      this.body = this.chapter.body;
      this.status = +this.chapter.status;

      if (this.status) {
        this.publishType = "publish";
      } else {
        if (this.chapter.date_to_publish) {
          this.publishType = "datePublish";
          this.dateToPublish = this.chapter.date_to_publish;
        } else {
          this.publishType = "draft";
        }
        if (this.chapter.time_to_publish) {
          this.timeToPublish = this.chapter.time_to_publish;
        }
      }
      if (this.book) {
        this.bookStatus = +this.book.status;
        this.oldBookStatus = this.bookStatus;
        this.bookFinish = +this.book.finish;
        this.oldBookFinish = this.bookFinish;
      }
    },
  },

  watch: {
    chapter() {
      if (this.chapter) {
        this.updateData();
        
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      chapter: "cabinet_chapters/chapter",
      book: "cabinet_chapters/book",
    }),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    minDate() {
      // Create new Date instance
      let date = new Date();

      // Add a day
      date.setDate(date.getDate() + 1);
      return date;
    },
  },
  mounted() {
    this.chapterId = this.$route.params.id;
    if (this.chapterId) {
      this.getChapter();
    } else {
      this.bookId = this.$route.params.bookId;
      this.getChapterCreate();
    }
    //Если имеется id - редактирование, нету - добавление
  },
};
</script>
