const toolbarOptions = [
  [{
    undo: 'undo'
  },
  {
    redo: 'redo'
  }], // toggled buttons
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  // ['link'], // link
  // ['blockquote', 'code-block'],
  [{
    'color': []
  }, {
    'background': []
  }],
  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{
    'list': 'ordered'
  }, {
    'list': 'bullet'
  }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{
    'indent': '-1'
  }, {
    'indent': '+1'
  }, 'blockquote'], // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction

  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  // [{ 'font': [] }],
  [{
    'align': []
  }],

  ['image'],// remove formatting button
  ['clean'] // remove formatting button
];
export { toolbarOptions };
